<template>
	<div>
		<page-title title="Chat Bot"></page-title>

		<v-row class="mt-2">
			<v-col cols="12" md="6" class="mt-3">
				<General :config="config" :isExtensionOnline="isExtensionOnline"></General>
			</v-col>
			<v-col cols="12" md="6" class="mt-3">
				<Timer
					:config="config"
					@onRangeDataStart="actions.onRangeDataStart"
					@onRangeDataEnd="actions.onRangeDataEnd"></Timer>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import axios from '@axios';
import { onBeforeUnmount, reactive, ref } from '@vue/composition-api';
import { ChatBotDefaults } from '../../utils/constants';
import { FormChangeWatcher } from '../../utils/form';
import { getExtensionStatus } from '../../utils/sticker';
import { MessageEnum } from '../../utils/constants';
import PageTitle from '@/components/PageTitle.vue';
import General from './chat-bot/General.vue';
import Timer from './chat-bot/Timer.vue';

export default {
	components: {
		PageTitle,
		General,
		Timer,
	},
	setup() {
		const config = reactive({
			message: null,
			command: null,
			minutes: ChatBotDefaults.MINUTES,
			lines: ChatBotDefaults.LINES,
			moreInfo: null,
			cooldown: ChatBotDefaults.COOLDOWN,
			enabled: ChatBotDefaults.ENABLED,
		});

		// Handle WS events.
		const eventBus = require('js-event-bus')();
		eventBus.on('ws-message', function (payload) {
			if (payload.method == MessageEnum.EXTENSION_STATUS) {
				isExtensionOnline.value = payload.value;
			}
		});

		// Create watcher for data changes.
		const formChangeWatcher = new FormChangeWatcher();
		onBeforeUnmount(() => {
			formChangeWatcher.stopWatching();
			eventBus.detachAll();
		});

		// Get extension status on initial load.
		const isExtensionOnline = ref(false);
		getExtensionStatus(data => {
			isExtensionOnline.value = data.online;
		});

		const actions = {
			loadData: () => {
				formChangeWatcher.stopWatching();

				axios.get('/bot/config').then(res => {
					// Copy data over.
					Object.assign(config, res.data);
					formChangeWatcher.startWatching(config, actions.saveChanges);
				});
			},
			saveChanges: () => {
				axios.put('/bot/config', config).catch(error => console.log(error));
			},
			onRangeDataStart: () => {
				formChangeWatcher.onRangeDataStart();
			},
			onRangeDataEnd: () => {
				formChangeWatcher.onRangeDataEnd();
			},
		};

		actions.loadData();

		return {
			actions,
			config,
			isExtensionOnline,
		};
	},
};
</script>
