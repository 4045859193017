<template>
	<v-card height="100%">
		<v-card-text>
			<section class="pb-4">
				<h3 class="fs--22 fw--400">Timer Message</h3>
				<h3 class="fs--14 fw--400 mt-3">Bot Message:</h3>
				<v-textarea
					v-model="config.message"
					@keydown="$emit('onRangeDataStart')"
					@focusout="$emit('onRangeDataEnd')"
					class="mt-1"
					flat
					background-color="purple"
					color="textColor"
					rows="2"
					no-resize
					solo
					hide-details></v-textarea>

				<p class="fieldFore--text fs--12 fw--400 mt-2 mb-0">
					This message will be displayed on an automatic timer as long as your browser source is active in
					your streaming software. Your viewers and mods can use the
					<span class="pink--text">{{ config.command }}</span> tag in chat to trigger the More Info message
					configured below.
				</p>
			</section>

			<v-divider class="purpleDivider"></v-divider>

			<section class="pt-3 pb-4">
				<v-row no-gutters class="align-center mb-0">
					<v-col cols="8" sm="10" md="9" lg="10" xl="10" class="pr-sm-3">
						<strong class="fs--14 fw--400"> Minutes passed before posting: </strong>
					</v-col>
					<v-col cols="4" sm="2" md="3" lg="2" xl="2">
						<input
							v-model="config.minutes"
							@keydown="$emit('onRangeDataStart')"
							@focusout="$emit('onRangeDataEnd')"
							type="number"
							oninput="validity.valid||(value='');"
							class="number-input" />
					</v-col>
				</v-row>
				<p class="fieldFore--text fs--12 fw--400 mt-3 mb-0">
					The time period between the automated Timer Messages.
				</p>
			</section>

			<v-divider class="purpleDivider"></v-divider>

			<section class="pt-3 pb-4">
				<v-row no-gutters class="align-center mb-0">
					<v-col cols="8" sm="10" md="9" lg="10" xl="10" class="pr-sm-3">
						<strong class="fs--14 fw--400"> Chat lines before posting: </strong>
					</v-col>
					<v-col cols="4" sm="2" md="3" lg="2" xl="2">
						<input
							v-model="config.lines"
							@keydown="$emit('onRangeDataStart')"
							@focusout="$emit('onRangeDataEnd')"
							type="number"
							oninput="validity.valid||(value='');"
							class="number-input" />
					</v-col>
				</v-row>
				<p class="fieldFore--text fs--12 fw--400 mt-3 mb-0">
					Number of chat messages that must be passed before the Timer Message will be displayed again. Please
					note both the time and chat lines must have passed before the bot will post again to chat.
				</p>
			</section>

			<v-divider class="purpleDivider"></v-divider>

			<section class="pt-3 pb-4">
				<v-row no-gutters class="align-center mb-0">
					<v-col cols="7" sm="9" md="8" xl="9" class="pr-sm-3">
						<strong class="fs--14 fw--400"> More info command: </strong>
					</v-col>
					<v-col cols="5" sm="3" md="4" xl="3">
						<input
							v-model="config.command"
							@keydown="$emit('onRangeDataStart')"
							@focusout="$emit('onRangeDataEnd')"
							type="text" />
					</v-col>
				</v-row>
				<p class="fieldFore--text fs--12 fw--400 mt-3 mb-0">
					Your viewers and mods can use this tag in chat to trigger the More Info message configured below.
				</p>
			</section>

			<v-divider class="purpleDivider"></v-divider>

			<section class="pt-3 pb-4">
				<h3 class="fs--14 fw--400 mt-3">
					More info <span class="info--text">{{ config.command }}</span> message:
				</h3>
				<v-textarea
					v-model="config.moreInfo"
					@keydown="$emit('onRangeDataStart')"
					@focusout="$emit('onRangeDataEnd')"
					class="mt-1"
					flat
					background-color="purple"
					color="textColor"
					rows="2"
					no-resize
					solo
					hide-details></v-textarea>
				<p class="fieldFore--text fs--12 fw--400 mt-3 mb-0">
					Additional message that will be displayed when a user types the specified More Info !command in
					chat.
				</p>
			</section>

			<v-divider class="purpleDivider"></v-divider>

			<section class="pt-3 pb-4">
				<v-row no-gutters class="align-center mb-0">
					<v-col cols="8" sm="10" md="9" lg="10" xl="10" class="pr-sm-3">
						<strong class="fs--14 fw--400">
							More info <span class="info--text">{{ config.command }}</span> cool down:
						</strong>
					</v-col>
					<v-col cols="4" sm="2" md="3" lg="2" xl="2">
						<input
							v-model="config.cooldown"
							@keydown="$emit('onRangeDataStart')"
							@focusout="$emit('onRangeDataEnd')"
							type="number"
							class="number-input"
							oninput="validity.valid||(value='');" />
					</v-col>
				</v-row>
				<p class="fieldFore--text fs--12 fw--400 mt-3 mb-0">
					The number of minutes before a viewer can use the specified More Info !command
				</p>
			</section>
		</v-card-text>

		<!-- <div class="text-center">
			<v-dialog v-model="dialog" width="500">
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" style="background-color: #4177e1"> Click Me </v-btn>
				</template>

				<v-card>
					<v-card-title class="background-pop text-center"
						style="padding-top: 5%; padding-bottom: 5%; text-align: center !important; display: block">
						Primary Header
					</v-card-title>

					<v-card-text class="second-header">Secondary Header example </v-card-text>

					<v-card-text class="small-text">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
						labore et dolore magna aliqua.
					</v-card-text>

					<v-card-text class="small-text">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
						labore et dolore magna aliqua.
					</v-card-text>

					<v-card-actions class="text-xs-center">
						<v-spacer> </v-spacer>
						<v-btn text @click="dialog = false" style="background-color: #e4328f"> Finished </v-btn>
						<v-spacer> </v-spacer>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div> -->
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	emits: ['onRangeDataStart', 'onRangeDataEnd'],
	setup() {
		const dialog = ref(false);

		return {
			dialog,
		};
	},
};
</script>

<style lang="scss" scoped>
// TODO Move styles to component along with dialog.
.background-pop {
	background-image: radial-gradient(rgb(65, 111, 222, 0.6), rgb(60, 175, 238, 0.6)),
		url('../../../assets/images/backgrounds/Shapes.png');
	background-size: contain;
}

.small-text {
	padding: 2% 8% !important;
	color: #7d59de !important;
	text-align: center;
}

.second-header {
	text-align: center;
	font-size: medium;
	font-weight: 400;
	padding-top: 6% !important;
}

input.number-input,
input[type='text'] {
	background: var(--v-purple-base);
	height: 30px;
	width: 100%;
	outline: none;
	color: white;
	padding: 10px;
	text-align: center;
	border-radius: 4px;
}
</style>
