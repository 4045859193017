<template>
	<v-card class="general-info" :min-height="$vuetify.breakpoint.xsOnly ? 500 : 800" height="100%">
		<v-card-text>
			<section class="pb-4">
				<h3 class="fs--22 fw--400">General</h3>
				<div class="d-flex justify-space-between align-end">
					<h3 class="fs--16 fw--400">Enable</h3>
					<v-switch color="pink" v-model="config.enabled" hide-details></v-switch>
				</div>
				<p class="fieldFore--text fs--12 fw--400 mt-2 mb-0">
					Displays automated messages in your chat room to let viewers know that Stream Stickers is enabled.
				</p>
			</section>

			<v-divider class="purpleDivider"></v-divider>

			<section class="py-4">
				<strong class="fs--16 fw--400">Browse Source Check</strong>
				<v-row align="center" class="mt-0 mb-0">
					<v-col cols="7" sm="9" md="8" lg="9" xl="10">
						<strong class="fs--13 fw--400">
							Ensure this status is 'ACTIVE' by configuring your
							<a href="" @click="actions.gotoSettings" class="textColor--text">Browser Source URL</a> in
							your streaming software
						</strong>
					</v-col>
					<v-col cols="5" sm="3" md="4" lg="3" xl="2" class="text-right">
						<v-chip :color="isExtensionOnline ? 'secondary' : 'red'">{{
							isExtensionOnline ? 'Active' : 'Offline'
						}}</v-chip>
					</v-col>
				</v-row>
				<p class="fieldFore--text fs--12 fw--400 mb-0">
					The bot will only start when Stream Stickers is active in your scene.
				</p>
			</section>

			<v-divider class="purpleDivider"></v-divider>

			<section class="py-4">
				<strong class="fs--16 fw--400">Moderator Status</strong>
				<v-row align="center" class="mt-0 mb-0">
					<v-col cols="9" md="8" lg="9" xl="10">
						<strong class="fs--13 fw--400">
							You can grant Stream Stickers moderator status
							<a
								:href="`https://dashboard.twitch.tv/u/${getUserName()}/community/roles`"
								class="textColor--text"
								target="_blank"
								>HERE</a
							>
							or by typing <span class="pink--text">/mod streamstickers</span> command in your chat.
						</strong>
					</v-col>
					<v-col cols="3" md="4" lg="3" xl="2" class="text-right">
						<img v-if="status" src="@/assets/images/misc/modIcon.png" />
						<img v-else src="@/assets/images/misc/disabled.png" />
					</v-col>
				</v-row>
				<p class="fieldFore--text fs--12 fw--400 mb-0">
					Please be sure to give #bot_user_name moderator status to prevent messages from being blocked due to
					spam or follower-only mode filters, etc.
				</p>
			</section>
		</v-card-text>

		<img class="chat-character d-none d-sm-block" width="100%" src="@/assets/images/mascot/ChatBotMascott.png" />
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api';
import { useRouter } from '@core/utils';
import { getUserName } from '@/utils/auth';
export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
		isExtensionOnline: {
			type: Boolean,
			required: true,
		},
	},
	setup(props, { emit }) {
		const status = ref(true); // TODO complete moderator status.
		const { router } = useRouter();

		const actions = {
			gotoSettings: () => {
				router.push('/settings');
			},
		};

		return {
			getUserName,
			actions,
			status,
		};
	},
};
</script>

<style lang="scss" scoped>
.general-info {
	position: relative;
}

.chat-character {
	position: absolute;
	left: -40px;
	bottom: -30px;
}

@media screen and (min-width: 1266px) and (max-width: 1420px) {
	.chat-character {
		left: -30px;
		bottom: -25px;
	}
}

@media screen and (min-width: 1024px) and (max-width: 1265px) {
	.chat-character {
		left: -30px;
		bottom: -24px;
	}
}

@media screen and (min-width: 960px) and (max-width: 1023px) {
	.chat-character {
		left: -40px;
		bottom: -24px;
	}
}

@media screen and (min-width: 768px) and (max-width: 959px) {
	.chat-character {
		width: 90%;
		left: -45px;
		bottom: -40px;
	}
}

@media screen and (max-width: 600px) {
	.chat-character {
		bottom: -26px;
	}
}

@media screen and (min-width: 0px) and (max-width: 547px) {
	.chat-character {
		bottom: -20px;
	}
}
</style>
